<template>
  <div>
    <div class="mb-3 flex items-center justify-between border-b pb-3">
      <div>
        <h2 class="text-xl font-semibold">Sửa bài hát</h2>
      </div>
      <div class="">
        <router-link :to="{name: 'AdminSongList'}"
                     class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
          Danh sách bài hát
        </router-link>
      </div>
    </div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:w-3/4">
        <div class="max-w-2xl m-auto shadow-md rounded-md bg-white mt-3">
          <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
            <div class="col-span-2">
              <label for="title" class="font-semibold">Tên bài hát (*)</label>
              <input v-model="song.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2">
              <label for="content" class="font-semibold">Lời bài hát <i class="far fa-question-circle"
                                                                        :uk-tooltip="'Chỉ ghi lời bài hát, không ghi tên bài hát, người trình bày,...'"></i></label>
              <editor id="content" v-model:content="song.content"/>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Trình bày <i class="far fa-question-circle"
                                                        :uk-tooltip="'Chọn người trình bày trong danh sách'"></i></label>
              <multiselect
                  v-model="artists"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchArtists"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                      <span class="multiselect-tag-remove-icon"></span>
                    </span>
                  </div>
                </template>
              </multiselect>

              <div class="mt-3">
                <div class="keyword-input-container shadow-none">
                    <input @keyup.enter="addArtistToSong" v-model="artist_title" type="text" class="keyword-input with-border" placeholder="Hoặc thêm nghệ sĩ mới">
                    <button @click="addArtistToSong" type="button" class="keyword-input-button ripple-effect">
                        <i class="icon-feather-plus"></i></button>
                </div>
              </div>
            </div>

            <div class="col-span-2">
              <label for="record_year" class="font-semibold">Năm thu âm</label>
              <input v-model="song.record_year" class="shadow-none with-border" id="record_year" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="subtitle" class="font-semibold">Ghi chú</label>
              <input v-model="song.subtitle" class="shadow-none with-border" id="subtitle" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Bản nhạc liên kết</label>
              <multiselect
                  v-model="sheet"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchSheets"
              >
                <template v-slot:option="{ option }">
                  {{ option.title }}
                  {{ option.year ? ' (' + option.year + ') ' : '' }}
                  {{ option.composers && option.composers.data ? ' - ' + getPeopleNames(option.composers.data) : ''}}
                </template>

                <template v-slot:singlelabel="{ value }">
                  <div class="multiselect-single-label">
                    {{ value.title }}
                    {{ value.year ? ' (' + value.year + ') ' : '' }}
                    {{ value.composers && value.composers.data ? ' - ' + getPeopleNames(value.composers.data) : ''}}
                  </div>
                </template>
              </multiselect>
              <div class="mt-2 flex justify-content-between">
                <router-link v-if="sheet.id" class="mr-2" :to="{name: 'AdminSheetEdit', params: {id: sheet.id}}">Sửa bản nhạc</router-link>
                <a href="javascript:void(0)" v-on:click="initSheet">Tạo bản nhạc</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="lg:w-1/4 w-full">
        <div>
          <label class="font-semibold">File nhạc (*)</label>
          <DropZone type="music" v-on:uploadDone="handleUploadDone" :musicTypeProp="musicType" :playerOptionsProp="playerOptions"
                    v-model:objectIdProp="song.file_id"/>
        </div>

        <div class="mt-4" v-if="song.file && song.file.type === 'video'">
          <label class="font-semibold">Kiểu hiển thị nhạc</label>
          <select v-model="song.file_type">
            <option value="audio">Audio</option>
            <option value="video">Video</option>
          </select>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Ảnh minh họa</label>
          <DropZone type="image" :imageProp="image" v-model:objectIdProp="song.image_id"/>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Đóng góp</label>
          <multiselect
              v-model="uploader"
              valueProp="id"
              label="username"
              trackBy="username"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchUsers"
          >
            <template v-slot:option="{ option }">
              <img class="w-10 h-10 rounded-full object-cover mr-3"
                   :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                option.username
              }}
            </template>

            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="w-7 h-7 rounded-full object-cover mr-3"
                     :src="value.avatar ? value.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                  value.username
                }}
              </div>
            </template>
          </multiselect>
        </div>

        <div class="mt-7">
          <div class="flex justify-content-between">
            <button type="button"
                    class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                    v-on:click="updateSong">
              Cập nhật
            </button>

            <router-link v-if="song.id"
                         :to="{name: 'SongDetail', params: {id: song.id, slug: song.slug, prefix: 'bai-hat'}}"
                         class="text-black hover:text-black bg-gray-300 hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
              Xem
            </router-link>

            <a class="text-white hover:text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
               href="#" id="deleteArtistBtn" uk-toggle="target: #deleteSongModal">
              Xóa
            </a>
          </div>
        </div>

        <div class="mt-7">
          <div class="flex justify-content-between">
            <button type="button"
                    class="text-black hover:text-black bg-gray-300 hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                    v-on:click="clearCacheTags">
              Xoá cache
            </button>
          </div>
        </div>
      </div>

      <div id="deleteSongModal" class="create-post is-story" uk-modal>
        <div
            class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">

          <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Xóa bài hát</h3>
            <button id="deleteSongModalClose" class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
                    type="button" uk-close
                    uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
          </div>

          <div class="px-5 py-2">
            <p>Bạn có chắc chắn muốn xóa bài hát <span class="font-semibold">{{ song.title }}</span>?</p>
          </div>

          <div class="flex items-center w-full justify-center border-t p-3">
            <a href="javascript:void(0)" v-on:click="deleteSong"
               class="bg-red-600 hover:bg-red-700 flex h-9 items-center justify-center rounded-lg text-white hover:text-white px-4 font-semibold">
              Xóa bài hát</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import Multiselect from '@vueform/multiselect'
import DropZone from "@/components/DropZone.vue";
import Editor from "../../Editor";
import {canManageSong, changePageTitle, closeModal, getPeopleNames} from "../../../core/services/utils.service";
import ApiService from "../../../core/services/api.service";

export default {
  title: "AdminSongEdit",
  components: {
    Editor,
    Multiselect,
    DropZone
  },
  data() {
    return {
      id: null,
      musicType: "audio",
      playerOptions: {
        autoplay: false,
        loop: false,
        controls: true,
        bigPlayButton: true,
        sources: []
      },
      image: null,
      song: {
        id: 0,
        title: "",
        content: "",
        subtitle: "",
        year: "",
        record_year: "",
        file_id: null,
        file_type: "",
        image_id: null,
        sheet_id: null,
        file: {
          type: "audio"
        }
      },
      uploader: {
        id: null
      },
      sheet: {
        id: null,
        slug: ''
      },
      artists: [],
      artist_title: "",
    }
  },
  methods: {
    loadSong() {
      let query = `query($id: ID!) {
        song(id: $id) {
          id
          title
          slug
          content
          subtitle
          record_year
          file_id
          file_type
          image_id
          sheet {
            id
            title
            slug
            year
            composers(first: 20, orderBy: [{column: "sheet_composer.order", order: ASC}]) {
              data {
                id
                title
                avatar {
                  url
                }
              }
            }
          }
          file {
            id
            audio
            video
            audio_url
            video_url
            type
          }
          thumbnail {
            id
            url
          }
          uploader {
            id
            username
            avatar {
              url
            }
          }
          artists(first: 100, orderBy: [{column: "song_artist.order", order: ASC}]) {
            data {
              id
              title
              avatar {
                url
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.song) {
              this.song = data.data.song;
              this.uploader = this.song.uploader;
              if (this.song.sheet) {
                this.sheet = this.song.sheet;
              }

              if (this.song.file) {
                this.musicType = this.song.file.type;
                let src = this.song.file.type === "audio" ? this.song.file.audio_url : this.song.file.video_url;
                let type = this.song.file.type === "audio" ? "audio/mp3" : "video/mp4";
                this.playerOptions.sources[0] = {
                  src: src,
                  type: type
                };
              }

              if (this.song.thumbnail) {
                this.image = this.song.thumbnail.url;
              }
              if (this.song.artists && this.song.artists.data) {
                this.artists = this.song.artists.data;
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadSheet() {
      let query = `query($id: ID!) {
        song(id: $id) {
          sheet {
            id
            title
            slug
            year
            composers(first: 20, orderBy: [{column: "sheet_composer.order", order: ASC}]) {
              data {
                id
                title
                avatar {
                  url
                }
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: this.id})
          .then(({data}) => {
            if (data.data && data.data.song && data.data.song.sheet) {
              this.sheet = data.data.song.sheet;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    updateSong() {
      if (!this.song.title) {
        this.$toast.error("Bạn chưa nhập tên bài hát");
        return;
      }

      if (!this.song.file_id) {
        this.$toast.error("Bạn chưa chọn file nhạc");
        return;
      }

      this.disabled = true;
      this.message = "";

      let query = `mutation($id: ID!, $title: String, $content: String, $subtitle: String, $record_year: String, $file_type: String, $file_id: ID, $image_id: ID, $sheet_id: ID, $uploader_id: ID, $sync_song_artist: [SyncSongArtist]) {
        updateSong(input: {
          id: $id,
          title: $title,
          content: $content
          subtitle: $subtitle
          record_year: $record_year
          file_type: $file_type
          file_id: $file_id
          image_id: $image_id
          sheet_id: $sheet_id
          uploader_id: $uploader_id
          artists: {
            sync: $sync_song_artist
          }
        }) {
          id
        }
      }`;

      let sync_song_artist = [];

      this.artists.map((item, index) => {
        sync_song_artist.push({id: item.id, order: index});
      });

      ApiService.graphql(query, {
        id: this.song.id,
        title: this.song.title,
        content: this.song.content,
        subtitle: this.song.subtitle,
        year: this.song.year,
        record_year: this.song.record_year,
        file_type: this.song.file_type,
        file_id: this.song.file_id,
        image_id: this.song.image_id,
        sheet_id: this.sheet ? this.sheet.id : null,
        uploader_id: this.uploader ? this.uploader.id : null,
        sync_song_artist: sync_song_artist,
      })
          .then(({data}) => {
            if (data.data && data.data.updateSong) {
              this.$toast.success("Cập nhật bài hát thành công");
              window.scrollTo(0, 0);
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    addArtistToSong() {
      if (!this.artist_title) {
        this.$toast.error("Bạn chưa nhập tên nghệ sĩ");
        return;
      }

      let query = `mutation($people_title: String!, $people_type: String!, $song_type: String!, $song_id: ID!) {
        addPeopleToSong(
          people_title: $people_title
          people_type: $people_type
          song_type: $song_type
          song_id: $song_id
        ) {
          id
        }
      }`;

      ApiService.graphql(query, {
        people_title: this.artist_title,
        people_type: "artist",
        song_type: "song",
        song_id: this.song.id
      })
      .then(({data}) => {
        if (data.data && data.data.addPeopleToSong) {
          this.$toast.success("Thêm nghệ sĩ thành công");
          this.artist_title = "";
          this.loadSong();
        } else {
          this.$toast.error(data.errors[0].message);
        }
      })
      .catch((response) => {
        this.$toast.error(response.message);
      });
    },
    initSheet() {
      let query = `mutation($song_id: ID!, $song_type: String!) {
        initSheetForSong(song_id: $song_id, song_type: $song_type) {
          id
          title
          slug
        }
      }`;

      ApiService.graphql(query, {
        song_id: this.song.id,
        song_type: "song"
      })
          .then(({data}) => {
            if (data.data && data.data.initSheetForSong) {
              this.loadSheet();
              this.$toast.success("Tạo bản nhạc thành công");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    deleteSong() {
      let query = `mutation($id: ID!) {
        deleteSong(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.song.id})
          .then(({data}) => {
            if (data.data && data.data.deleteSong) {
              this.$toast.success("Xóa bài hát thành công");
              closeModal("deleteSongModal");
              this.$router.push({name: "AdminSongList"});
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    async searchArtists(q) {
      let query = `query($q: Mixed) {
        artists(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.artists && data.data.artists.data) {
              return data.data.artists.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchSheets(q) {
      let query = `query($q: Mixed, $id: Mixed) {
        sheets(first: 50, orderBy: [{column: "id", order: DESC}], where: {OR: [{column: "title", operator: LIKE, value: $q}, {column: "id", value: $id}]}) {
          data {
            id
            title
            year
            composers(first: 20) {
              data {
                id
                title
                slug
              }
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `%${q}%`, id: q})
          .then(({data}) => {
            if (data.data && data.data.sheets && data.data.sheets.data) {
              return data.data.sheets.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    clearCacheTags() {
      let query = `mutation($tags: String!) {
        clearCacheTags(tags: $tags)
      }`;

      ApiService.graphql(query, {
        tags: `song-${this.id}`,
      })
      .then(({data}) => {
        if (data.data && data.data.clearCacheTags) {
          this.$toast.success("Xoá cache thành công");
          this.loadSong();
        } else {
          this.$toast.error(data.errors[0].message);
        }
      })
      .catch((response) => {
        this.$toast.error(response.message);
      });
    },
    handleUploadDone(data) {
      if (data && data.type) {
        this.song.file_type = data.type;
        this.song.file.type = data.type;
      }
    },
    getPeopleNames(x) {
      return getPeopleNames(x);
    }
  },
  computed: {
    canManageSong() {
      return canManageSong();
    }
  },
  mounted() {
    if (!canManageSong()) {
      this.$router.push({name: "Home"});
    }

    changePageTitle("Sửa bài hát");
    this.id = this.$route.params.id;
    this.loadSong();
  },
  beforeRouteUpdate(to, from, next) {
    this.id = to.params.id;
    this.loadSong();
    window.scrollTo(0, 0);
    next();
  },
}
</script>
